// 引入request
import requests from '../../Utils/request'

// 登录
export const cjlogin = (data) => requests({  
  url: '/auth/login',
  method: 'post',
  data: data
})

// 获取风险点列表
export const getRiskList = (id) => requests({
  url: `document/file/risk/list?id=${id}`,
  // url: 'document/file/risk/list?id=1',
  method: 'get',
  // params
})
// 获取风险点列表
export const getRiskDetail= (id) => requests({
  url: `document/file/risk/detail?id=${id}`,
  method: 'get',
})
//下载文件
// export const uploadText = (data) => requests({
//     url: '/api/Risk/DownFile',
//     method: 'post',
//     data
// })
