<template>
  <div class="entry">
    <div class="left-lateral">
      <!-- 入职 -->
      <div class="title">风险点</div>
      <div class="table-box">
        <p
          :class="tableVal == item2 ? 'is-p' : ''"
          v-for="item2 in tableList"
          @click="selectTable(item2)"
          :key="item2">
          {{item2}}
        </p>
      </div>
      <div class="course-box">
        <p
          :class="`label ${labelId == item.id ? 'in-label' : ''}`"
          @click="checkLabel(item)"
          v-for="item in labelList"
          :key="item.id"
        >
          {{ item.title }}
          <span v-if="labelId == item.id"></span>
        </p>
      </div>
    </div>
    <div class="right-lateral">
      <div class="card" v-if="detailInfo.title">
        <div class="img">
          <img src="@/assets/images/zngj/fxzy-1.png" alt="" />
        </div>
        <div class="content-box">
          <p class="title">风险点</p>
          <p class="title-val">{{ detailInfo.title }}</p>
        </div>
      </div>
      <div class="card" v-if="detailInfo.mistakes">
        <div class="img">
          <img src="@/assets/images/zngj/fxzy-2.png" alt="" />
        </div>
        <div class="content-box">
          <p class="title">常见失误</p>
          <p class="title-val" v-html="detailInfo.mistakes"></p>
        </div>
      </div>
      <div class="card" v-if="detailInfo.legalRisk">
        <div class="img">
          <img src="@/assets/images/zngj/fxzy-3.png" alt="" />
        </div>
        <div class="content-box">
          <p class="title">法律风险</p>
          <p class="title-val" v-html="detailInfo.legalRisk"></p>
        </div>
      </div>
      <div class="card" v-if="detailInfo.legalBasis">
        <div class="img">
          <img src="@/assets/images/zngj/fxzy-4.png" alt="" />
        </div>
        <div class="content-box">
          <p class="title">法律依据</p>
          <p class="title-val" v-html="detailInfo.legalBasis"></p>
        </div>
      </div>
      <div class="card" v-if="detailInfo.operaStandard">
        <div class="img">
          <img src="@/assets/images/zngj/fxzy-5.png" alt="" />
        </div>
        <div class="content-box">
          <p class="title">操作规范</p>
          <p class="title-val" v-html="detailInfo.operaStandard"></p>
        </div>
      </div>
      <div class="card" v-if="tempList.length > 0">
        <div class="img">
          <img src="@/assets/images/zngj/fxzy-6.png" alt="" />
        </div>
        <div class="content-box">
          <p class="title">法律文本</p>
          <div class="file-box">
            <div
              class="file-card"
              v-for="item in tempList"
              :key="item.id"
              @click="lookWord(item)"
            >
              <img src="@/assets/images/zngj/fxzy-7.png" />
              <p>{{ item.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="查看文书"
      :visible.sync="isLookWrit"
      width="850px"
      height="500px"
      center
      top="0"
    >
      <div id="box">
        <div id="placeholder"></div>
      </div>
      <p class="btn">
        <el-button type="primary" round @click="download()">下载</el-button>
        <el-button round @click="isLookWrit = false">取消</el-button>
      </p>
    </el-dialog>
    <remote-js :src="CX_Api" />
  </div>
</template>

<script>
import { getRiskList, getRiskDetail } from "@/FackApi/api/tool.js";
import { viewInitEditor } from "@/Utils/viewonlineEdit";
export default {
  name: "entry",
  components: {
    "remote-js": {
      render(createElement) {
        return createElement("script", {
          attrs: { type: "text/javascript", src: this.src },
        });
      },
      props: {
        src: { type: String, required: true },
      },
    },
  },
  data() {
    return {
      labelList: [],
      labelId: "",
      detailInfo: {},
      tempList: [],
      isLookWrit: false,
      createId: "", //创建ID
      fileInfo: {},
      tableList: ['入职','在职','离职'],
      tableVal: '入职',
    };
  },
  created() {},
  mounted() {
    console.log(this.src);
    this.getRiskListInfo();
  },
  methods: {
    getRiskListInfo() {
      let id = 0;
      if (this.tableVal == '入职') {
        id = 0;
      } else if (this.tableVal == '在职') {
        id = 1;
      } else if (this.tableVal == '离职') {
        id = 2;
      }
      getRiskList(id).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          // let data = res;
          this.labelList = res.data;
          this.labelId = this.labelList[0].id;
          this.getRiskDetailInfo();
        }
      });
    },
    getRiskDetailInfo() {
      getRiskDetail(this.labelId).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.detailInfo = res.data;
          this.tempList = res.temp;
        }
      });
    },
    // 切换table栏
    selectTable(val) {
      this.tableVal = val;
      this.getRiskListInfo();
    },
    checkLabel(item) {
      this.labelId = item.id;
      this.getRiskDetailInfo();
    },
    lookWord(item) {
      this.fileInfo = item;
      this.isLookWrit = true;
      this.createId = item.id;
      this.$nextTick(() => {
        let el = document.getElementById("box");
        el.innerHTML = '<div id="placeholder"></div>';
        viewInitEditor(
          item.title,
          item.path,
          true,
          true,
          false,
          false,
          String(item.id),
          "view",
          "desktop|mobile",
          {
            comment: true,
            download: false,
            edit: true,
            print: false,
            review: true,
          },
          item.title,
          this.COOKIE_TOKEN,
          100
        );
      });
    },
    //点击下载
    download() {
      // this.isLookWrit = false;
      let ext = this.fileInfo.path.substring(this.fileInfo.path.lastIndexOf('.'));
      this.getBlob(this.fileInfo.path).then(blob => {
        this.DownloadItem(blob, this.fileInfo.title+ext);
      });
    },
    //下载 重命名 3
      getBlob(url) {
          return new Promise(resolve => {
          const xhr = new XMLHttpRequest();
          xhr.open('GET', url, true);
          xhr.responseType = 'blob';
          xhr.onload = () => {
            if (xhr.status === 200) {
              resolve(xhr.response);
            }
          };
          xhr.send();
        });
      },
       DownloadItem(blob,fileName){
         let link = document.createElement('a');
         link.href = window.URL.createObjectURL(blob);
         link.download = fileName;
         link.click();
    },
  },
};
</script>

<style lang="less" scoped>
.entry {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .left-lateral {
    width: 15%;
    // height: 62vh;
    border-radius: 18px;
    box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
    background-color: #fff;
    overflow: hidden;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      // height: 8%;
      height: 70px;
      font-size: 16px;
      color: #837af3;
      background: linear-gradient(270deg, #f0e4fd 0%, #e6e4fd 100%);
    }
    .table-box {
      display: flex;
      // height: 7%;
      height: 50px;
      margin: 1% 4px 2%;
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33.33%;
        cursor:pointer;
      }
      .is-p {
        color: #6ce6f4;
        background-color: #e5fafc;
        border-radius: 4px;
      }
    }
    .course-box {
      // height: 80%;
      padding-left: 16px;
      margin: 1% 0;
      overflow-y: auto;
      .label {
        position: relative;
        padding: 10px 0 10px 16px;
        border-left: 6px solid #eef1f7;
        cursor: pointer;
        span {
          position: absolute;
          top: 50%;
          left: -12px;
          transform: translate(0, -50%);
          display: block;
          width: 18px;
          height: 18px;
          border-radius: 10px;
          background: #fff;
          border: 4px solid #8471e2;
          box-sizing: border-box;
        }
      }
      .in-label {
        font-weight: bold;
        color: #837af3;
      }
    }
  }

  .right-lateral {
    width: 83%;
    // height: 62vh;
    overflow-y: auto;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
    border-radius: 18px;
    padding: 30px;
    .card {
      display: flex;
      flex-direction: inherit;
      margin-bottom: 30px;
      .img {
        width: 66px;
        img {
          width: 100%;
        }
      }
      .content-box {
        width: 100%;
        margin-left: 20px;
        .title {
          font-size: 16px;
          font-weight: bold;
          color: #333;
        }
        .title-val {
          font-size: 14px;
          // color: #909399;
          padding: 10px 0 30px 0;
          border-bottom: 1px solid #dddfe6;
          line-height: 30px;
        }
        .file-box {
          display: flex;
          flex-wrap: wrap;
          margin-top: 15px;
          .file-card {
            width: 225px;
            height: 225px;
            border-radius: 18px;
            border: 1px solid #dddfe6;
            margin: 0 20px 20px 0;
            text-align: center;
            cursor: pointer;
            img {
              margin: 50px 0 40px;
            }
            p {
              font-size: 14px;
              color: #333;
            }
          }
        }
      }
    }
  }
}

::v-deep .el-dialog{
    border-radius:10px;
    position: absolute;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  ::v-deep .el-dialog__header{
    background:#eee;
    padding:10px;
    border-radius:10px 10px 0 0;
  }
  ::v-deep .el-dialog__close{
    display:none;
  }
  ::v-deep .el-dialog__title{
    font-size:14px;
  }
  ::v-deep .el-dialog__body{
    width:100%;
    height: calc(100% - 44px);
    padding: 0;
    #box{
      height: calc(100% - 50px);
    }
  }
  #placeholder{
    height: 100%;
    width:100%;
  }
  .btn{
    display: block !important;
    text-align: center;
    padding:5px;
    ::v-deep .el-button{
      width:135px;
      height:36px;
      line-height: 12px;
      border-radius: 18px;
      margin: 0 20px;
    }
    ::v-deep .el-button--primary{
      background-image: linear-gradient(#318DFA,#0261D5);
    }
  }
p {
  margin-bottom: 0 !important;
}
</style>
