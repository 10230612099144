<template>
  <b-container fluid>
    <div class="form-check-boxes">
    <b-row>
      <b-col lg="12">
        <iq-card>
          <!-- <template v-slot:headerTitle>
            <h4 class="card-title">入在离风险指引</h4>
          </template> -->
          <template v-slot:body>
            <div class="iq-search-bar">
              <p class="all-crumbs">智能工具 > 入在离风险指引</p>
              <form action="#" class="" v-if="false">
                <label for="validationDefault01">关键字</label>
                <input
                  type="text"
                  class="text search-input"
                  style="cursor: pointer"
                  v-model="globalSearch"
                  placeholder="请输入关键词"
                />
                <button
                  class="btn mr-1 btn-primary rounded-pill seeAbout"
                  type="submit"
                  @click="getFileListInfo"
                >
                  查询
                </button>
                <button
                  class="btn mr-1 btn-secondary rounded-pill reset"
                  type="submit"
                  @click="reset"
                >
                  重置
                </button>
              </form>
            </div>
            <!-- <div class="table-box">
              <p
                :class="`label ${tableVal == items ? 'in-label':''}`"
                @click="checkLabel(items)"
                v-for="items in tableList"
                :key="items"
                >{{ items }}</p
              >
            </div> -->
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12" v-if="false">
        <entry></entry>
      </b-col>
    </b-row>
    </div>
    <el-dialog
      title="使用提示"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      width="460px"
    >
      <p style="text-align: center;">入在离风险指引 暂未对体验会员开放，如需使用请联系我们！</p>
      <img
        style="width: 400px;"
        src="@/assets/images/kf-img.png"
        alt="">
      <div class="btns-box">
        <button class="btn" @click="dialogVisible = false">关 闭</button>
      </div>
    </el-dialog>
  </b-container>
</template>

<script>
import { core } from '../../config/pluginInit'
import entry from './FormCheckboxes/entry.vue';
export default {
  name: "FormCheckboxes",
  components: {
    entry,
  },
  mounted() {
    core.index()
  },
  data() {
    return {
      tableList: ['入职','在职','离职'],
      tableVal: '入职',
      globalSearch: "",

      dialogVisible: true,
    };
  },
  created() {
  },
  methods: {
    checkLabel(val) {
      this.tableVal = val;
    },
    getFileListInfo() {
      this.dialogVisible = true;
    },
    reset() {},
  },
};
</script>
<style lang="less" scoped>
.container-fluid {
  margin-top: 20px;
}
.form-check-boxes {
  .table-box {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: -20px;
    .label {
      width: 100px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #333;
      padding-bottom: 10px;
      border-bottom: 8px solid #fff;
      cursor:pointer;
    }
    .in-label {
      color: #837AF3;
      border-bottom: 8px solid #e8e6f9;
    }
  }
}
.iq-search-bar {
  // border-bottom: 1px solid #e7e7e7;
  // padding-bottom: 20px !important;
  // margin-bottom: 20px;
}
.search-input {
  width: 300px !important;
  margin: 0 50px 0 10px !important;
  background: #eaf5ff !important;
}
.seeAbout {
  width: 100px;
  border-radius: 50px;
}
.reset {
  width: 100px;
  border-radius: 50px;
  background: #c0c4cc;
  margin-left: 10px !important;
}
p {
  margin-bottom: 0 !important;
}
.all-crumbs {
  margin-bottom: 20px !important;
}
  .btns-box {
    display: flex;
    justify-content: center;
    .btn {
      width: 120px;
      height: 40px;
      color: #fff;
      border-radius: 8px;
      background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
      border: none;
      margin-bottom: 20px;
    }
  }
</style>
